$theme-colors: (
    "primary": #56BEC2,
    "secondary": #FDC40E,
    "tertiary": #80A5EB,
    "gray":#5b5b5b,
    "body":#171717,
    "white":#F2F2F2,
    "light": #ffffff,
    "dark": #313131,
    "danger": #c00000,
    "warning":#c49506,
    "success": #28a745,
    "info": #50d5e9,
);


$font-family-sans-serif: 'Inter',
    -apple-system,
    sans-serif;

$border-radius: .6rem !default;
$border-radius-sm: .8rem !default;
$border-radius-lg: .8rem !default;

$body-bg: #171717;


$enable-responsive-font-sizes: true;

:root {
    --primary: #56BEC2;
    --secondary: #FDC40E;
    --tertiary: #80A5EB;
    --gray: #5b5b5b;
    --lead: var(--gray);
    --primary-50: #4c83e87c;


    --bg-color: #171717;
    --bg-color-contrast: #292929;
    --dark-50: #3131319c;
    --text-color: #F2F2F2;

    --border-radius: 0.5rem;
    --navbar-height: 8vh;
    --lh: 1.2rem;
}



html,
body,
#root {
    margin: 0;
    scroll-behavior: smooth;
    background-color: var(--bg-color) !important;
    margin: 0;
    padding: 0;
    color: var(--text-color) !important;
    line-height: var(--lh);

    min-height: 100vh;
}

*::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
}

*::-webkit-scrollbar-thumb {
    border-radius: $border-radius;
    background-color: var(--primary);
    transition: 0.3s;
}

*::-webkit-scrollbar-thumb:hover {
    border-radius: 10px;
    background-color: var(--primary);
    transition: 0.3s;
}

*::-webkit-scrollbar-track {
    border-radius: $border-radius;
    background-color: transparent;

}

.bg-frost {
    background-color: rgba(255, 255, 255, 0.668) !important;
    backdrop-filter: blur(5px);
}

.selectable {
    cursor: pointer;
}

.text-xs {
    font-size: 0.6rem;
}






@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;700&display=swap');
@import '../node_modules/@fortawesome/fontawesome-free/css/all.css';


.nav-link:hover {
    color: var(--primary) !important;
    transition: all 0.3s ease-in-out;
}

.container-custom {
    max-width: 1480px;
    margin: 0 auto;
    padding: 0 1rem;
}

.indicator-active::before {
    content: '';
    position: absolute;
    top: 105%;

    width: 20px;
    height: 3px;
    background-color: var(--primary);
    border-radius: 0.5rem;
    animation: fadeInUp 0.3s ease-in-out;
}

.indicator-active {
    position: relative;
}



.top-negative {
    background-color: #F2F2F2 !important;
    backdrop-filter: blur(20px);
    transition: all 0.3s ease-in-out;
    color: var(--text-color) !important;
}

.translateOut {
    transform: translateY(-100%);
    transition: all 0.3s ease-in-out;
}


@keyframes zoomInSmall {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.95, 0.95, 0.95);
        transform: scale3d(0.95, 0.95, 0.95);
    }

    to {
        opacity: 1;
    }
}

.zoomInSmall {
    -webkit-animation-name: zoomInSmall;
    animation-name: zoomInSmall;
}

@keyframes zoomInSmallReverse {
    from {
        opacity: 0;
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }

    to {
        opacity: 1;
    }
}

.zoomInSmallReverse {
    -webkit-animation-name: zoomInSmallReverse;
    animation-name: zoomInSmallReverse;
}

@-webkit-keyframes slideOutUp {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

@keyframes slideOutUp {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

.slideOutUp {
    -webkit-animation-name: slideOutUp;
    animation-name: slideOutUp;

}

@-webkit-keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}





.slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
}


@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 5%, 0);
        transform: translate3d(0, 5%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

.animated {
    -webkit-animation-duration: .9s;
    animation-duration: .9s;

}

.animated.fast {
    -webkit-animation-duration: 500ms;
    animation-duration: 500ms;
}

.animated.faster {
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
}

.animated.slow {
    -webkit-animation-duration: 2s;
    animation-duration: 1.5s;
}

.animated.delay {
    -webkit-animation-delay: .3s;
    animation-delay: .2s;
}